// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-lundgren-gatsby-theme-lundgren-blog-src-pages-404-js": () => import("./../../../node_modules/@lundgren/gatsby-theme-lundgren-blog/src/pages/404.js" /* webpackChunkName: "component---node-modules-lundgren-gatsby-theme-lundgren-blog-src-pages-404-js" */),
  "component---node-modules-lundgren-gatsby-theme-lundgren-blog-src-pages-portfolio-js": () => import("./../../../node_modules/@lundgren/gatsby-theme-lundgren-blog/src/pages/portfolio.js" /* webpackChunkName: "component---node-modules-lundgren-gatsby-theme-lundgren-blog-src-pages-portfolio-js" */),
  "component---node-modules-lundgren-gatsby-theme-lundgren-blog-src-templates-page-js": () => import("./../../../node_modules/@lundgren/gatsby-theme-lundgren-blog/src/templates/page.js" /* webpackChunkName: "component---node-modules-lundgren-gatsby-theme-lundgren-blog-src-templates-page-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

